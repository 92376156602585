import dayjs, { ManipulateType, OpUnitType, Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { createHash } from "crypto";
import { CSSProperties } from "react";

dayjs.extend(isBetween);
dayjs.extend(utc);
dayjs.extend(timezone);

import { APP_KEYS } from "@/globals/constants";

// store
import { AppointmentTypeList } from "@/store/services/practice";
import { AppointmentBriefListing } from "@/store/services/scheduling";
import { AppointmentInfo } from "@/store/services/patient";
import { EventInfo } from "@/store/services/event";

// styles
import styles from "./styles.module.scss";

interface ComponentMap {
  [key: string]: React.ComponentType<any>;
}

// Calculate the elapsed time in pixels
function getPixelsElapsed() {
  const currentTime = dayjs(); // Get the current time using dayjs
  const startTime = dayjs().startOf("day").add(0, "hours"); // Set the start time at 9am
  const minutesToPixelsRatio = 65 / 30; // Ratio of minutes to pixels (height of cell is 65px )
  const minutesElapsed = currentTime.diff(startTime, "minutes"); // Calculate the number of minutes elapsed
  return minutesElapsed * minutesToPixelsRatio; // Calculate the number of pixels
}

export { getPixelsElapsed };

// USE THIS FUNCTION WHEN CONVERTING 12 DIGIT DATETIMES
export const convertUtcIntToLocalDatetime = (timestampInt: number) => {
  // Get UTC timestamp
  const utcTimestamp = dayjs.utc(timestampInt?.toString());
  // Get client timezone
  const tz = dayjs.tz.guess();
  // Convert UTC slots to the local time of the client machine
  const localDatetime = dayjs(utcTimestamp).tz(tz);
  return localDatetime;
};

// USE THIS FUNCTION WHEN CONVERTING 6 DIGIT DATES
export const convertUtcIntToLocalDate = (dateInt: number) => {
  // Dates are stored as 6 digit integers in the format YYYYMMDD. Note that
  // this does not include the time component, so it is not timezone dependent.
  if (dateInt.toString().length > 8) {
    // This is a timestamp, so we need to convert it to the local timezone, and then
    // extract the date component
    dateInt = parseInt(
      convertUtcIntToLocalDatetime(dateInt).format("YYYYMMDD")
    );
  }

  const year = Math.floor(dateInt / 10000);
  const month = Math.floor((dateInt % 10000) / 100);
  const day = dateInt % 100;

  return dayjs(
    `${year}-${month.toString().padStart(2, "0")}-${day.toString().padStart(2, "0")}`
  );
};

//  GetLocalDatetime
export const convertLocalDatetimeToUtcInt = (localDatetime: Dayjs) => {
  if (!localDatetime) {
    return;
  }
  // convert to UTC
  const utcDatetime = localDatetime?.utc();
  // Convert the dayjs object to the dateTimeInt format expected by morula backend
  const dateTimeIntFormat = utcDatetime.format("YYYYMMDDHHmmss");
  const dateTimeInt = parseInt(dateTimeIntFormat);
  return dateTimeInt;
};

export const convertLocalDatetimeToUtcDate = (localDatetime: Dayjs) => {
  // convert to UTC
  const utcDatetime = localDatetime.utc();

  // Convert the dayjs object to the dateTimeInt format expected by morula backend
  const dateTimeIntFormat = utcDatetime.format("YYYYMMDD");
  const dateTimeInt = parseInt(dateTimeIntFormat);
  return dateTimeInt;
};

// rename GetUTCInt or EncodeDatetime
export const apptSlots = (day: string, increment = 15) => {
  const startHour = 0; // Start hour (7am) // TODO: get from practice preferences
  const endHour = 24; // End hour (9pm)
  const incrementMinutes = increment; // Increment in minutes

  // Check if this is a DST transition day
  const startOfDay = dayjs(day);
  const [isDstTransition, isFallBack] = (() => {
    const midnightBefore = startOfDay.subtract(1, "minute");
    const midnightAfter = startOfDay.add(24, "hours");
    const isDST = midnightBefore.utcOffset() !== midnightAfter.utcOffset();
    const isFallBack = midnightBefore.utcOffset() > midnightAfter.utcOffset();
    return [isDST, isFallBack];
  })();

  let timestamps = Array.from(
    { length: (endHour - startHour) * (60 / incrementMinutes) },
    (_, index) =>
      dayjs(day)
        .startOf("day")
        .hour(startHour)
        .add(index * incrementMinutes, "minute")
        .toISOString()
  );

  if (!isDstTransition) {
    return timestamps;
  }

  // If this is a DST transition day, we need to adjust the timestamps
  // use 2am as the transition time because we are currently based in N. America
  // there are 2 transitions in a year, one in spring and one in fall
  // there are 4 timeslots per hour, so 2am is the 8th slot
  // spring forward: 2am becomes 3am

  if (isFallBack) {
    // Remove duplicated hour (4 slots for 15-min increments)
    timestamps = [...timestamps.slice(0, 8), ...timestamps.slice(12)];
  } else {
    // Spring forward: 2am becomes 3am
    return [
      ...timestamps.slice(0, 8),
      ...timestamps.slice(4, 8), // duplicate 2am slot (will render as 2 1ams in calendar slots)
      ...timestamps.slice(8, 92) // remove the last 4 slots
    ];
  }

  return timestamps;
};

const calculateHeight = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
  const durationInMinutes = end.diff(start, "minutes"); // total duration in minutes
  const slotDuration = 15; // slots represent 15 mins
  const slotHeight = 29; // height of each slot in pixels (30px - 1px for space)
  return (durationInMinutes / slotDuration) * slotHeight;
};

export const getAppointmentHeight = (appointment: AppointmentBriefListing) => {
  const start = convertUtcIntToLocalDatetime(appointment.start_time);
  const end = convertUtcIntToLocalDatetime(appointment.end_time);
  return calculateHeight(start, end);
};

export const getEventHeight = (event: EventInfo) => {
  const start = dayjs(event.start_at);
  const end = dayjs(event.end_at);
  return calculateHeight(start, end);
};

export const getEventIcon = (event: EventInfo) => {
  if (event.event_type.includes("ON_CALL_IN_CLINIC")) {
    return "on_call_in_clinic";
  } else if (event.event_type.includes("ON_CALL")) {
    return "on_call";
  } else {
    return "in_office";
  }
};

export const stringSlots = [
  "12:00 AM",
  "12:15 AM",
  "12:30 AM",
  "12:45 AM",
  "1:00 AM",
  "1:15 AM",
  "1:30 AM",
  "1:45 AM",
  "2:00 AM",
  "2:15 AM",
  "2:30 AM",
  "2:45 AM",
  "3:00 AM",
  "3:15 AM",
  "3:30 AM",
  "3:45 AM",
  "4:00 AM",
  "4:15 AM",
  "4:30 AM",
  "4:45 AM",
  "5:00 AM",
  "5:15 AM",
  "5:30 AM",
  "5:45 AM",
  "6:00 AM",
  "6:15 AM",
  "6:30 AM",
  "6:45 AM",
  "7:00 AM",
  "7:15 AM",
  "7:30 AM",
  "7:45 AM",
  "8:00 AM",
  "8:15 AM",
  "8:30 AM",
  "8:45 AM",
  "9:00 AM",
  "9:15 AM",
  "9:30 AM",
  "9:45 AM",
  "10:00 AM",
  "10:15 AM",
  "10:30 AM",
  "10:45 AM",
  "11:00 AM",
  "11:15 AM",
  "11:30 AM",
  "11:45 AM",
  "12:00 PM",
  "12:15 PM",
  "12:30 PM",
  "12:45 PM",
  "1:00 PM",
  "1:15 PM",
  "1:30 PM",
  "1:45 PM",
  "2:00 PM",
  "2:15 PM",
  "2:30 PM",
  "2:45 PM",
  "3:00 PM",
  "3:15 PM",
  "3:30 PM",
  "3:45 PM",
  "4:00 PM",
  "4:15 PM",
  "4:30 PM",
  "4:45 PM",
  "5:00 PM",
  "5:15 PM",
  "5:30 PM",
  "5:45 PM",
  "6:00 PM",
  "6:15 PM",
  "6:30 PM",
  "6:45 PM",
  "7:00 PM",
  "7:15 PM",
  "7:30 PM",
  "7:45 PM",
  "8:00 PM",
  "8:15 PM",
  "8:30 PM",
  "8:45 PM",
  "9:00 PM",
  "9:15 PM",
  "9:30 PM",
  "9:45 PM",
  "10:00 PM",
  "10:15 PM",
  "10:30 PM",
  "10:45 PM",
  "11:00 PM",
  "11:15 PM",
  "11:30 PM",
  "11:45 PM"
].map(slot => ({ value: slot, label: slot }));

export function getUtcStartAndEndFromDatetime(
  date: string, // YYYY-MM-DD
  startTime: string, // h:mm A
  endTime: string // h:mm A
): [number, number] {
  // Combine the date and time
  const startDatetimeStr: string = `${date} ${startTime}`;
  const endDatetimeStr: string = `${date} ${endTime}`;

  // Create a Day.js object
  const startTimeObj = dayjs(startDatetimeStr, "YYYY-MM-DD h:mm A");
  const endTimeObj = dayjs(endDatetimeStr, "YYYY-MM-DD h:mm A");

  // Convert to UTC
  const utcStart = convertLocalDatetimeToUtcInt(startTimeObj) as number;
  const utcEnd = convertLocalDatetimeToUtcInt(endTimeObj) as number;

  return [utcStart, utcEnd];
}

/**
 * Retrieves an array of all days in a given year.
 * @param year - The year for which to retrieve the days.
 * @returns Array of dayjs objects representing each day in the year.
 */
export function getAllDaysOfYear(year: number): Dayjs[] {
  const startDate: Dayjs = dayjs(`${year}-01-01`)
    .startOf("day")
    .subtract(1, APP_KEYS.WEEK as ManipulateType);
  const endDate: Dayjs = dayjs(`${year}-12-31`)
    .startOf("day")
    .add(1, APP_KEYS.WEEK as ManipulateType); // buffer one week for year

  const totalDays: number = endDate.diff(startDate, "day") + 1;
  return [...Array(totalDays)].map(
    (_, i: number): Dayjs => startDate.add(i, "day")
  );
}

export function getAllTimestamps(year: number): string[] {
  return getAllDaysOfYear(year).map(day => day.toISOString());
}

export function convertTimestamps(array: string[]): Dayjs[] {
  return array.map(timestamp => dayjs(timestamp));
}
/**
/**
 * Retrieves the index of a specified element in an array of dayjs objects.
 * @param array - The array to search in.
 * @param element - The element to search for.
 * @returns The index of the element in the array, or -1 if not found.
 */
function getIndex(array: Dayjs[], element: Dayjs): number {
  return array.findIndex((el: Dayjs): boolean => el.isSame(element));
}

/**
 * Retrieves an array of weekdays (Sunday to Saturday) centered around a specified index.
 * @param array - The array of dayjs objects.
 * @param timestamp - The timestamp around which to retrieve the weekdays.
 * @returns Array of dayjs objects representing the weekdays.
 */
export function getWeekdays(array: string[], timestamp: string): string[] {
  const date = dayjs(timestamp).startOf("day");
  const days = convertTimestamps(array);
  const index: number = getIndex(days, date);
  const startIdx: number = index - (index % 7);
  const endIdx: number = startIdx + 6;
  // Sunday - Saturday
  return days.slice(startIdx - 1, endIdx).map(d => d.toISOString());
}

/**
 * Retrieves an array of dayjs objects representing a specific month.
 * @param timestamps - The array of timestamps.
 * @param date - The date within the month for which to retrieve the timestamps.
 * @returns Array of dayjs objects representing the month.
 */
export function getMonth(timestamps: string[], timestamp: string): string[] {
  const days = convertTimestamps(timestamps);
  const date = dayjs(timestamp);
  const startOfMonth: Dayjs = date.startOf(APP_KEYS.MONTH as OpUnitType);
  const endOfMonth: Dayjs = date
    .endOf(APP_KEYS.MONTH as OpUnitType)
    .startOf("day");
  const startOfMonthIdx: number = days.findIndex((t: Dayjs): boolean =>
    t.isSame(startOfMonth, "day")
  );
  const endOfMonthIdx: number = days.findIndex((t: Dayjs): boolean =>
    t.isSame(endOfMonth, "day")
  );
  const startOfMonthDay: number = startOfMonth.day();
  const endOfMonthDay: number = endOfMonth.day();
  const firstDayToShowIdx: number = startOfMonthIdx - startOfMonthDay;
  const lastDayToShowIdx: number = endOfMonthIdx + (6 - endOfMonthDay);
  return days
    .slice(firstDayToShowIdx, lastDayToShowIdx + 1)
    .map(d => d.toISOString());
}

// get provider & location filtered events
export function getEventsFilteredByProviderAndLocation(
  providerFilter: number[],
  locationFilter: (string | number)[],
  events: EventInfo[]
) {
  // check that the event participants includes some provider in the filter
  const providerFilteredEvents = events?.filter(event =>
    event?.participants?.some(participant =>
      providerFilter.includes(participant.user_id)
    )
  );

  const locationFilteredEvents = providerFilteredEvents?.filter(
    event => event.location_id && locationFilter.includes(event.location_id)
  );

  return locationFilteredEvents;
}

export function getDay(utcTimestamp: number) {
  return parseInt(utcTimestamp?.toString().slice(0, 8));
}

const calendarColors = [
  styles.calendarOne,
  styles.calendarTwo,
  styles.calendarThree,
  styles.calendarFour,
  styles.calendarFive,
  styles.calendarSix,
  styles.calendarSeven,
  styles.calendarEight,
  styles.calendarNine,
  styles.calendarTen,
  styles.calendarElevent,
  styles.calendarTwelve,
  styles.calendarThirteen,
  styles.calendarFourteen,
  styles.calendarFifteen,
  styles.calendarSixteen,
  styles.calendarSeventeen,
  styles.calendarEighteen,
  styles.calendarNineteen,
  styles.calendarTwenty
];

export function userIdToHexCode(userID: number): string {
  const hash = createHash("sha256");
  const hashedUserID = hash.update(String(userID)).digest("hex");
  // Use a subset of the hash to determine the index
  const idx =
    parseInt(hashedUserID.substring(0, 2), 16) % calendarColors.length;
  return calendarColors[idx];
}

export function appointmentTypeToHexCode(
  appointmentType: string,
  appointment_hex?: string
): string {
  if (appointment_hex) {
    return `#${appointment_hex}`;
  }
  const hash = createHash("sha256");
  const hashedAppointmentType = hash.update(appointmentType).digest("hex");
  // Use a subset of the hash to determine the index
  const idx =
    parseInt(hashedAppointmentType.substring(0, 2), 16) % calendarColors.length;
  return calendarColors[idx];
}

export function getTextColorForBackground(bgColor: string) {
  if (!bgColor) return "#000000"; // Default to black (for white backgrounds
  // Convert hex color to RGB
  const rgb = hexToRgb(bgColor);

  // Calculate relative luminance
  const luminance = (0.2126 * rgb.r + 0.7152 * rgb.g + 0.0722 * rgb.b) / 255;

  // Choose white or black text based on luminance threshold
  return luminance > 0.5 ? "#000000" : "#ffffff"; // Use black for light backgrounds, white for dark backgrounds
}

function hexToRgb(hex: string) {
  // Remove '#' if present
  hex = hex.replace(/^#/, "");

  // Convert to RGB
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
}

export function getAppointmentStyle(
  appointment: AppointmentBriefListing,
  colorByProvider: boolean,
  appointmentTypes?: AppointmentTypeList
) {
  const baseStyle: CSSProperties = {
    backgroundColor: colorByProvider
      ? userIdToHexCode(appointment.provider_id)
      : appointmentTypeToHexCode(
          appointment.appointment_type,
          appointmentTypes?.find(
            type => type.appointment_type === appointment.appointment_type
          )?.appointment_hex
        ),
    height: getAppointmentHeight(appointment),
    color: getTextColorForBackground(
      colorByProvider
        ? userIdToHexCode(appointment.provider_id)
        : appointmentTypeToHexCode(
            appointment.appointment_type,
            appointmentTypes?.find(
              type => type.appointment_type === appointment.appointment_type
            )?.appointment_hex
          )
    )
  };

  return baseStyle;
}
