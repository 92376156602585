import { createSlice } from "@reduxjs/toolkit";

type TabId = string; // TODO: stricter typing
type initialState = {
  tabId: TabId;
};

const initialState: initialState = {
  tabId: "",
};

export const tabSlice = createSlice({
  name: "tab",
  initialState,
  reducers: {
    setTabId: (state, action) => {
      state.tabId = action.payload;
    },
  },
});

export const { setTabId } = tabSlice.actions;

export default tabSlice.reducer;
